
































































































import { Component, Prop, Vue, Provide, Watch } from 'vue-property-decorator';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { Debounce } from 'lodash-decorators';
import { Order, Payment } from '@/dtos/Account';
import { Getter, Action } from 'vuex-class';
import {
  ReactiveSubscribe,
  Subscribe,
  runInterval,
  mounted,
} from '@/utils/decorators/VueTimmer';
import { MAX_CONFIRMING_BLOCK } from '@/configs/constant';
import { returnAtIndex } from 'lodash-decorators/utils';
import messaging from '@/services/messaging';
import { Subscription } from 'rxjs';

@Component({
  components: {
    VueQrcode,
  },
})
export default class Deposit extends Vue {
  MAX_CONFIRMING_BLOCK = MAX_CONFIRMING_BLOCK;
  messageSubscription: Subscription = null;

  @Getter('account/payments')
  payments: Record<string, Payment>;

  @Getter('account/deltaTime')
  deltaTime: number;

  get paymentId(): string {
    return <any>this.$route.query.payment_id;
  }

  get payment(): Payment {
    return this.payments[this.paymentId];
  }

  get address() {
    return this.payment && this.payment.address;
  }

  get txhash() {
    return this.payment && this.payment.transaction_hash;
  }

  get isPaymentSuccess() {
    return (
      this.payment &&
      ((this.payment.status == 'confirming' &&
        this.payment.confirmation >= this.MAX_CONFIRMING_BLOCK) ||
        this.payment.status == 'success')
    );
  }

  @mounted
  subscribleMessage() {
    let messageSubscription = messaging.subscribe({
      next: (message) => {
        console.log(message);
      },
    });

    this.messageSubscription = messageSubscription;
  }

  get isPaymentExpired() {
    return !this.isPaymentSuccess && this.isExpired;
  }

  @Provide()
  timestamp = Date.now();

  @Provide()
  showCopySuccess = false;

  @Provide()
  showCopyAmountSuccess = false;

  @runInterval(1000)
  updateTimestamp() {
    this.timestamp = Date.now();
  }

  @Action('account/getPaymentDetail')
  _updatePaymentDetail(paymentId: string) {}

  @runInterval(5000)
  updatePaymentDetail(paymentId = this.paymentId) {
    if (this.isPaymentSuccess) {
      return;
    }

    return this._updatePaymentDetail(paymentId);
  }

  get currentTime() {
    return this.timestamp + this.deltaTime;
  }

  get expiredIn() {
    return this.payment.expire_at - this.currentTime;
  }

  get isExpired() {
    return this.expiredIn <= 0;
  }

  onCopySuccess() {
    this.showCopySuccess = true;
    this.hideCopySuccess();
  }

  @Debounce(1000)
  hideCopySuccess() {
    this.showCopySuccess = false;
  }

  onCopyAmountSuccess() {
    this.showCopyAmountSuccess = true;
    this.hideCopyAmountSuccess();
  }

  @Debounce(1000)
  hideCopyAmountSuccess() {
    this.showCopyAmountSuccess = false;
  }

  @Watch('isPaymentSuccess')
  onIsPaymentSuccess(newVal: boolean) {
    if (newVal) {
      this.$store.dispatch('account/getPendingPlan');
    }
  }
}
